/* Dropdowns */

.dropdown {
  .dropdown-menu {
    margin-top: .75rem;
    font-size: $default-font-size;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);    
    .dropdown-item {
      font-size: 1rem;
      padding: .25rem 1.5rem;
      &:active {
        background: initial;
      }
    }
  }
}
.dropdown-toggle {
  &:after {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f078";  
    width: auto;
    height: auto;
    vertical-align: baseline;
    font-size: .75rem;
  }
}

.dropup {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: "\f077";  
      width: auto;
      height: auto;
      vertical-align: baseline;
      font-size: .75rem;
    }
  }
}