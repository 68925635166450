/* Lists */

ul,
ol,
dl {
  padding-left: 1rem;
  font-size: $default-font-size;
  li {
    line-height: 1.8;
  }
}

.list-ticked,
.list-arrow,
.list-star {
  list-style: none;
  padding: 0;
  li {
    padding-left: 1.5rem;
    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: .5rem;
    }
  }
}

.list-ticked {
  li {
    &:before {
      content: "\f00c";
      color: theme-color(danger);
    }
  }
}

.list-arrow {
  li {
    &:before {
      content: "\f061";
      color: theme-color(success);
    }
  }
}

.list-star {
  li {
    &:before {
      content: "\f005";
      color: theme-color(warning);
    }
  }
}

.solid-bullet-list {
  position: relative;
  padding-left: 0;
  .rtl & {
    padding-right: 0;
  }
  li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 1rem;
    &:last-child {
      padding-bottom: .25rem;
    }
    &:before,
    &:after {
      content:"";
      position: absolute;
    }
    &:before {
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      z-index: 1;
    }
    &:nth-child(1) {
      &:before {
        background: theme-color(success);
      }
    }
    &:nth-child(2) {
      &:before {
        background: theme-color(danger);
      }
    }
    &:nth-child(3) {
      &:before {
        background: theme-color(info);
      }
    }
    &:nth-child(4) {
      &:before {
        background: theme-color(warning);
      }
    }
    &:nth-child(5) {
      &:before {
        background: theme-color(primary);      
      }
    }
  }
  &:after {
    content: "";
    border: 1px solid $border-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4px;
    z-index: 0;
  }
}