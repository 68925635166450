/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}
.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;

  .input-group-text {
    border-color: $border-color;
    padding: $input-padding-y .75rem;
    color: $input-placeholder-color;
  }
}
.form-control {
  border: 1px solid $border-color;
  font-weight: 400;
  font-size: $input-font-size;
  @include input-placeholder {
    color: $input-placeholder-color;
    font-weight: 400;
  }
  &:focus {
    outline: 0;
  }
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    color: $input-placeholder-color;
    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        border: 1px solid $value;
      }
    }
  }
}
.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
