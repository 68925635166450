.profile-page {
  .profile-header {
    width: 100%;
    background: url("../images/samples/profile_page/profile_header_banner.jpg") no-repeat center center;
    background-size: cover;
    padding: 60px 0;
    @include border-radius(5px);

    .profile-info {
      .profile-user-name {
        margin-bottom: 0;
        font-family: $type1;
        font-weight: 600;
      }

      .profile-user-designation {
        margin-bottom: 0;
        font-family: $type1;
      }

      .br-wrapper {
        padding-left: 15px;

        .br-widget {
          height: auto;
          @include transform(translateY(-5px));

          a {
            font-size: 18px;
            margin-right: 0;
          }
        }
      }
      @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .details {
      @include display-flex;
      @include align-items(center);
      @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 15px;
      }
      .detail-col {
        padding-right: 20%;
        border-right: 1px solid rgba($border-color,0.3);
        margin-right: 20%;

        &:last-child {
          border-right: none;
          margin-right: 0;
        }

        p {
          margin-bottom: 0;
          font-weight: 400;

          &:first-child {
            font-weight: 700;
          }
        }
      }
    }
  }

  .profile-body {
    padding-top: 50px;

    .tab-switch {
      border-bottom: 1px solid $border-color;

      .nav-item {
        .nav-link {
          color: $text-muted;
          font-size: 1rem;
          padding-bottom: 1.2rem;
          font-weight: 600;
          border-bottom: 3px solid $white;
          @include transition-duration(0.4s);
          @include transition-timing-function(ease-in);
          @include transition-property(color,border-color);

          &.active {
            color: $black;
            border-bottom: 3px solid theme-color(primary);
          }
        }
      }
    }

    .tab-body {
      border: none;
      padding: 10px 0;

      .stages {
        position: relative;

        .stage-badge {
          position: absolute;
          top: 0;
          left: -20px;
        }

        .file-icon-wrapper {
          @include display-flex;
          @include flex-direction(row);
          @include flex-wrap(wrap);

          .file-icon {
            height: 77px;
            width: 69px;
            padding: 5px;
            margin-right: 25px;
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);

            i {
              font-size: 43px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}