@font-face {
  font-family: 'PTsans';
  src: url('../fonts/PT_Sans/PTSans-Regular.eot');
  src: url('../fonts/PT_Sans/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/PT_Sans/PTSans-Regular.woff2') format('woff2'),
      url('../fonts/PT_Sans/PTSans-Regular.woff') format('woff'),
      url('../fonts/PT_Sans/PTSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PTsans';
  src: url('../fonts/PT_Sans/PTSans-Bold.eot');
  src: url('../fonts/PT_Sans/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/PT_Sans/PTSans-Bold.woff2') format('woff2'),
      url('../fonts/PT_Sans/PTSans-Bold.woff') format('woff'),
      url('../fonts/PT_Sans/PTSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}